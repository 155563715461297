import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "./Particle";
import profile from "../Assets/OIP.jpeg";
function Contact() {
  // const [email, setEmail] = useState("");
  // const [subject, setSubject] = useState("");

  // const handleSubmit = async () => {
  //   // console.log(email);
  //   const response = await fetch("https://www.tryuntilyourock.com/contact", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ email, subject}),
  //   });

  //   const data = await response.json();
  //   setEmail("")
  //   setSubject("")
  //   console.log(data);
  // };
  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row
          className="my-element"
          style={{ justifyContent: "center", position: "relative" }}
        >
          Contact Details
        </Row>
        <Row>
          <Col>
            <img src={profile} alt="Profile" className="my-element"></img>
          </Col>
          <br></br>
          <p style={{ justifyContent: "left", position: "" }}>
            Madhu Koppu<br></br>
            Mail queries at madhukoppu10@gmail.com
          </p>
        </Row>

        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <label>
            Email&nbsp;
            <br></br>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ maxWidth: "250px" }}
            />
          </label>
        </Row>
        <br />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <label>
            Your Thoughts&nbsp;
            <br></br>
            <textarea
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              style={{ maxWidth: "250px" }}
            />
          </label>
        </Row> */}
        <Row style={{ justifyContent: "center", position: "relative" }}>
          Please fill out the google form by clicking the below botton!!
        </Row>
        <br></br>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <a href="https://forms.gle/jFNq9esGcKppeJW16">
            <Button style={{ maxWidth: "250px" }}>&nbsp;Click here to fill the form!</Button>
          </a>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
